import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

interface RestaurantCardProps {
  restaurantName: string;
  data: OrderDTO[];
  link?: string;
}

const RestaurantCard = ({
  restaurantName,
  data,
  link = '',
}: RestaurantCardProps) => {
  const [locations, setLocations] = useState<string[]>([]);

  useEffect(() => {
    const set = new Set<string>();
    data.map((row) => {
      set.add(row.userAt);
    });
    setLocations(Array.from(set));
  }, []);
  return (
    <div>
      <Card sx={{ minWidth: 275, textAlign: 'left' }}>
        <CardContent>
          <Typography variant="h5" component="div">
            {restaurantName}
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary"></Typography>
          {locations.map((location) => (
            <>
              <Divider />
              <Typography variant="overline" component="div">
                {location}
              </Typography>
              {data.map((row) => {
                if (
                  row.userAt === location &&
                  row.restaurantName === restaurantName
                ) {
                  return (
                    <>
                      <Typography variant="subtitle2">{`${row.userName}`}</Typography>
                      <Typography variant="body2">
                        {`${row.orderMenu}`}
                        <br />
                        <br />
                      </Typography>
                    </>
                  );
                }
              })}
              {!data.some(
                (row) =>
                  row.userAt === location &&
                  row.restaurantName === restaurantName,
              ) && <Typography variant="body2">없음</Typography>}
            </>
          ))}
        </CardContent>
        <CardActions>
          <Button
            size="small"
            onClick={() => {
              window.open(link);
            }}
          >
            식당 링크 바로가기
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

export default RestaurantCard;
