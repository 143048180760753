/* eslint-disable @typescript-eslint/no-empty-function */
import {
  QueryClientProvider,
  useQueries,
  useQueryClient,
} from '@tanstack/react-query';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Grid,
} from '@mui/material';
import { useEffect, useState } from 'react';
import RestaurantCard from './RestaurantCard';
import useMobile from '../hooks/useMobile';

const TodayPage = () => {
  const { isMobile } = useMobile();
  const [restaurantNamesOfToday, setRestaurantNamesOfToday] = useState<
    string[]
  >([]);

  const queryClient = useQueryClient();

  const [ordersQuery, restaurantsQuery] = useQueries({
    queries: [
      {
        queryKey: ['orders'],
        queryFn: () =>
          axios
            .get('https://bot.theorigin.group/today')
            .then((res) => res.data),
        refetchInterval: 1000 * 30,
      },
      {
        queryKey: ['restaurants'],
        queryFn: () =>
          axios
            .get('https://bot.theorigin.group/restaurants')
            .then((res) => res.data),
      },
    ],
  });

  // const { isLoading, error, data, dataUpdatedAt } = useQuery({});

  useEffect(() => {
    if (ordersQuery.data) {
      const set = new Set<string>();
      ordersQuery.data.map((row: OrderDTO) => {
        set.add(row.restaurantName);
      });
      setRestaurantNamesOfToday([...set]);
    }
  }, [ordersQuery.data]);

  useEffect(() => {}, [ordersQuery.dataUpdatedAt]);

  if (ordersQuery.isLoading) return <>Loading...</>;

  if (ordersQuery.error)
    return <>`An error has occurred: ${JSON.stringify(ordersQuery.error)}`</>;

  return (
    <QueryClientProvider client={queryClient}>
      <div>
        <Typography
          variant="caption"
          display="block"
          gutterBottom
          sx={{ textAlign: 'right' }}
        >
          Last updated:{' '}
          {JSON.stringify(new Date(ordersQuery.dataUpdatedAt).toLocaleString())}
        </Typography>
        <Grid container spacing={2}>
          {restaurantNamesOfToday.map((r: any) => {
            const link = restaurantsQuery.data.find(
              (res: any) => res.name === r,
            )?.link;
            return (
              <Grid item xs={12} md={4}>
                <RestaurantCard
                  restaurantName={r}
                  link={link}
                  data={ordersQuery.data}
                />
              </Grid>
            );
          })}
        </Grid>
        {!isMobile && (
          <TableContainer sx={{ marginTop: '50px' }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>이름</TableCell>
                  <TableCell>식당 ▼</TableCell>
                  <TableCell>메뉴</TableCell>
                  <TableCell>위치</TableCell>
                  <TableCell>주문시간</TableCell>
                </TableRow>
              </TableHead>
              {ordersQuery.data.length > 0 && (
                <TableBody>
                  {ordersQuery.data.map((row: OrderDTO) => (
                    <TableRow
                      key={row.userName}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.userName}
                      </TableCell>
                      <TableCell>{row.restaurantName}</TableCell>
                      <TableCell sx={{ maxWidth: 400 }}>
                        {row.orderMenu}
                      </TableCell>
                      <TableCell>{row.userAt}</TableCell>
                      <TableCell>
                        {new Date(row.orderedAt).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              )}
              {ordersQuery.data.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      component="th"
                      scope="row"
                      colSpan={5}
                      sx={{ textAlign: 'center' }}
                    >
                      주문이 없습니다.
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </div>
    </QueryClientProvider>
  );
};

export default TodayPage;
