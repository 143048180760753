import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import TodayPage from './components/TodayPage';
import { Container } from '@mui/material';

function App() {
  const Home = () => {
    return <div>OK</div>;
  };
  return (
    <div className="App">
      <Container>
        <Router>
          <Routes>
            <Route path="/lunch/today" element={<TodayPage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </Container>
    </div>
  );
}

export default App;
